import React, { FC } from "react";
import { QuickPitchWrapper } from "./QuickPitch.styled";
import { SquareButton } from "shared/shared_styled_comps/forms.styled";
import LogoWithText from "static/svgs/LogoWithText";
import { LogoWrapper } from "components/Login/Login.styled";
import AuthSparkle from "static/svgs/AuthSparkle";
import Sparkle2 from "static/svgs/Sparkle2";
import Import from "static/svgs/Import";
import CodeBlock from "static/svgs/CodeBlock";
import BarChart from "static/svgs/BarChart";
import Wheel from "static/svgs/Wheel";
import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";
import { useNavigate, useLocation } from "react-router-dom";

interface QuickPitchProps {
  button?: boolean;
  logo?: boolean;
  navigate?: Function;
  signup?: boolean;
}

const QuickPitch: FC<QuickPitchProps> = ({
  button,
  navigate,
  logo,
  signup,
}) => {
  const location = useLocation();

  const redirectWithParams = () => {
    const newPath = "/signup";
    navigate && navigate(`${newPath}${location.search}`);
  };

  return (
    <QuickPitchWrapper signup={signup}>
      {logo && (
        <LogoWrapper className="logo">
          <LogoWithText />
        </LogoWrapper>
      )}
      <div className="pitch-inner-wrapper">
        <div className="driven-wrapper">
          <AuthSparkle />
          <h1>AI-Driven</h1>
        </div>
        <h2 className="coaching">Sales Coaching</h2>
        <h4>
          Shave hours off your week by making smarter decisions to close your
          deals faster
        </h4>
        <ul>
          <li>
            <Import />
            <p>Easily import your calls</p>
          </li>
          <li>
            <Sparkle2 />
            <p>Instant Feedback</p>
          </li>
          <li>
            <Wheel />
            <p>Tailored Support</p>
          </li>
          <li>
            <CodeBlock />
            <p>AI Deal Assessments</p>
          </li>
          <li>
            <BarChart />
            <p>Real-time predictive insights</p>
          </li>
        </ul>
        {button && (
          <MarketingGreenButtonWrapper
            onClick={redirectWithParams}
            width={400}
          >
            Get Started for Free
          </MarketingGreenButtonWrapper>
        )}
        <div className="next-steps">
          <h4>Questions?</h4>
          <p>
            Email us at <a href="mailto:info@leadoff.ai">info@leadoff.ai</a>
          </p>
        </div>
        <div className="next-steps">
          <h4>Need a team solution?</h4>
          <p>
            <a href="mailto:info@leadoff.ai">Book a Call with Us</a>
          </p>
        </div>
      </div>
    </QuickPitchWrapper>
  );
};

export default QuickPitch;
