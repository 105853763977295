import React, {
  MutableRefObject,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import MarketingSectionLabel from "../../MarketingSectionLabel/MarketingSectionLabel";
import { colors } from "colors";
import ArrowsPointLeft from "static/svgs/ArrowsPointLeft";
import Code from "static/svgs/Code";
import People from "static/svgs/People";
import TrendUp from "static/svgs/TrendUp";
import Stars from "static/svgs/Stars";
import {
  SaveTimeSectionWrapper,
  MarketingFeaturesWrapper,
  MarketingFeature,
} from "./SaveTimeSection.styled";
import { StructuredText } from "react-datocms";
import { GreenButton } from "shared/shared_styled_comps/components.styled";
import { useNavigate } from "react-router-dom";

interface HomePage {
  saveTimeHeader?: any;
  saveTimeSubHeader?: string;
}

type Props = {
  // ref: MutableRefObject<HTMLDivElement | null>
  data: HomePage;
};

const SaveTimeSection: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  props: Props,
  ref
) => {
  const navigate = useNavigate()
  return (
    <SaveTimeSectionWrapper ref={ref}>
      <div className="row">
        <div className="section-left">
          <MarketingSectionLabel fontWeight={500} text="Save Time and Effort" />
          {props.data.saveTimeHeader && (
            <StructuredText data={props.data.saveTimeHeader.value} />
          )}
          {props.data.saveTimeSubHeader && (
            <h4>{props.data.saveTimeSubHeader}</h4>
          )}
        </div>
        <div className="section-right">
          <ArrowsPointLeft />
        </div>
      </div>
      <MarketingFeaturesWrapper>
        {/* <MarketingFeature
          marginBottom={15}
          marginTop={15}
          marginRight={7.5}
          backgroundColor="gradient"
        >
          <Stars />
          <h3>Personalized Coaching</h3>
          <h4>
            Your manager doesn’t have time to coach you. Fortunately, our AI
            Deal Coach is a sales expert ready to help whenever you need.
          </h4>
        </MarketingFeature>
        <MarketingFeature
          marginBottom={15}
          marginTop={15}
          marginLeft={7.5}
          backgroundColor="black"
        >
          <Code />
          <h3>Qualitative Deal Summary</h3>
          <h4>
            Quickly review the key information from your deal while preparing
            for your next prospect interaction.
          </h4>
        </MarketingFeature> */}
        <MarketingFeature
          marginBottom={15}
          marginTop={15}
          marginRight={7.5}
          backgroundColor="black"
        >
          <People />
          <h3>Automation For Sales Reps</h3>
          <ul>
            <li>Precall Research</li>
            <li>Deal Analysis</li>
            <li>Next Steps and Action Items</li>
            <li>Personalized Deal Coaching</li>
            <li>CMS Resource Identification</li>
          </ul>
          <GreenButton
            width={"fit-content"}
            height={`fit-content`}
            fontSize={25}
            paddingTop={15}
            paddingBottom={15}
            paddingleft={40}
            paddingright={40}
            onClick={() => navigate("/solutions-reps")}
          >
            Learn More
          </GreenButton>
        </MarketingFeature>
        <MarketingFeature
          marginBottom={15}
          marginTop={15}
          marginLeft={7.5}
          backgroundColor="gradient"
        >
          <TrendUp />
          <h3>Automation For Sales Leaders</h3>
          <ul>
            <li>Deal Analysis</li>
            <li>Pipeline Reviews</li>
            <li>Scale Coaching Expertise</li>
            <li>Identifcation of Team + Individual Performance Trends</li>
            <li>Summarization of Sales Data Across Systems</li>
          </ul>
          <GreenButton
            width={"fit-content"}
            height={`fit-content`}
            fontSize={25}
            paddingTop={15}
            paddingBottom={15}
            paddingleft={40}
            paddingright={40}
            onClick={() => navigate("/solutions-leaders")}
          >
            Learn More
          </GreenButton>
        </MarketingFeature>
      </MarketingFeaturesWrapper>
    </SaveTimeSectionWrapper>
  );
};

export default forwardRef(SaveTimeSection);
