import React, { FC, useEffect, useState } from "react";
import { getDealsQuick, getIncomingCalls, putIncomingCalls } from "api/helpers";
import moment from "moment-timezone";
import {
  addDays,
  startOfWeek,
  format,
  addWeeks,
  differenceInCalendarDays,
} from "date-fns";
import {
  CalendarWrapper,
  WeekNavigation,
  DayButton,
  CallsContainer,
  CallItem,
  Time,
  CallInfo,
  Host,
  HostInitial,
  WeekToggle,
  ToggleButton,
  CalendarLoader,
} from "./Calendar.styled";
import Arrow from "static/svgs/Arrow";
import { CalendarCall, QuickDeal, User } from "types";
import { ToggleSwitch } from "shared/shared_components/components";
import { GreenButton } from "shared/shared_styled_comps/components.styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DropDown } from "shared/shared_styled_comps/forms.styled";
import Edit from "static/svgs/Edit";

interface CalendarProps {}

const Calendar: FC<CalendarProps> = () => {
  const navigate = useNavigate();
  const user: User = useSelector((state: any) => state.auth.user);

  const [callsLoading, setCallsLoading] = useState(false);
  const [deals, setDeals] = useState<QuickDeal[]>([]);
  const [orgDeals, setOrgDeals] = useState<QuickDeal[]>([]);
  const [weekOffset, setWeekOffset] = useState(0);
  const [weeklyCalls, setWeeklyCalls] = useState<
    Record<string, CalendarCall[]>
  >({});

  // Calculate the start of the current week based on today's date
  const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 0 });

  // Calculate the start of the week based on the weekOffset
  const startOfDisplayedWeek = addWeeks(startOfCurrentWeek, weekOffset);

  // Generate the dates for the current week
  const weekDates = Array.from({ length: 7 }, (_, i) =>
    addDays(startOfDisplayedWeek, i)
  );

  // Find today's date index within the current week
  const todayIndex = differenceInCalendarDays(new Date(), startOfDisplayedWeek);

  // Initialize selectedDay with todayIndex
  const [selectedDay, setSelectedDay] = useState(
    todayIndex >= 0 && todayIndex < 7 ? todayIndex : 0
  );

  const handlePrevWeek = () => setWeekOffset(weekOffset - 1);
  const handleNextWeek = () => setWeekOffset(weekOffset + 1);

  // Function to organize meetings by date
  const organizeMeetingsByDate = (meetings: CalendarCall[]) => {
    const meetingsByDate: Record<string, CalendarCall[]> = {};

    meetings.forEach((meeting) => {
      // Convert meeting_time to a Date object using UTC time
      const meetingTime = moment.parseZone(meeting.meeting_time);

      const dateStr = meetingTime.format("YYYY-MM-DD");

      if (!meetingsByDate[dateStr]) {
        meetingsByDate[dateStr] = [];
      }

      meetingsByDate[dateStr].push(meeting);
    });

    // Sort the meetings by time within each date
    for (const date in meetingsByDate) {
      meetingsByDate[date].sort((a, b) => {
        const timeA = new Date(a.meeting_time).getTime();
        const timeB = new Date(b.meeting_time).getTime();
        return timeA - timeB; // Sort in ascending order
      });
    }

    return meetingsByDate;
  };

  const handleDealChange = (
    callIndex: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newDealId = event.target.value;
    const newDeal = newDealId === "New Deal";

    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      deal_id: newDealId,
      new_deal: newDeal,
      editing: newDeal,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });

    // Optionally update the backend here if needed
    putIncomingCalls({
      incoming_call_id: updatedCalls[callIndex].incoming_call_id,
      deal_id: newDealId,
    });
  };

  const updateWebsite = (
    callIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const website = event.target.value;

    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      website: website,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });
  };

  const submitWebsite = (callIndex: number, website: string) => {
    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      website: website,
      editing: false,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });

    // Optionally update the backend here if needed
    putIncomingCalls({
      incoming_call_id: updatedCalls[callIndex].incoming_call_id,
      website: website,
    });
  };

  const handleEdit = (callIndex: number) => {
    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      editing: true,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });
  };

  const handleToggle = (
    dayIndex: number,
    callIndex: number,
    call: CalendarCall
  ) => {
    const selectedDateStr = format(weekDates[dayIndex], "yyyy-MM-dd");
    const updatedCalls = [...(weeklyCalls[selectedDateStr] || [])];

    if (updatedCalls[callIndex]) {
      let current_record = updatedCalls[callIndex].record;
      // Toggle the 'record' property
      updatedCalls[callIndex] = {
        ...updatedCalls[callIndex],
        record: !current_record,
      };

      // Update the state with the modified calls
      setWeeklyCalls({
        ...weeklyCalls,
        [selectedDateStr]: updatedCalls,
      });

      // Update the backend
      putIncomingCalls({
        incoming_call_id: call.incoming_call_id,
        record: !current_record,
      });
    }
  };

  useEffect(() => {
    const getCalls = async () => {
      setCallsLoading(true);
      let response = await getIncomingCalls(weekOffset);
      if (response?.data?.meetings) {
        const organizedMeetings = organizeMeetingsByDate(
          response.data.meetings
        );
        console.log("orged meetings: ", organizedMeetings);
        setWeeklyCalls(organizedMeetings);
        setCallsLoading(false);
      }
    };
    const loadDeals = async () => {
      let response = await getDealsQuick();
      setDeals(response);
    };
    const loadOrgDeals = async () => {
      let response = await getDealsQuick(true);
      setOrgDeals(response);
    };
    const promiseAll = async () => {
      await Promise.all([getCalls(), loadDeals(), loadOrgDeals()]);
    };
    if (user && user.google_token && user.google_token !== "") {
      promiseAll();
    }
  }, [user, weekOffset]);

  const dealIdToName = (dealId: string) => {
    if (orgDeals) {
      const deal = orgDeals.find((d) => d.id === dealId);
      if (deal) {
        return deal.name;
      }
    }
    return "";
  };

  const selectedDateStr = format(weekDates[selectedDay], "yyyy-MM-dd");
  const callsForSelectedDay = weeklyCalls[selectedDateStr] || [];

  return (
    <CalendarWrapper>
      <h2>My calendar</h2>
      <WeekToggle>
        <ToggleButton onClick={handlePrevWeek}>
          <Arrow />
        </ToggleButton>
        <div className="week-title">{`Week of ${format(
          startOfDisplayedWeek,
          "MMMM d, yyyy"
        )}`}</div>
        <ToggleButton className="right" onClick={handleNextWeek}>
          <Arrow />
        </ToggleButton>
      </WeekToggle>

      <WeekNavigation>
        {weekDates.map((date, index) => (
          <DayButton
            key={index}
            selected={selectedDay === index}
            onClick={() => setSelectedDay(index)}
          >
            {format(date, "EEEE, MMM d")}
          </DayButton>
        ))}
      </WeekNavigation>

      {callsLoading ? (
        <CalendarLoader />
      ) : (
        <CallsContainer>
          {user && user.google_token && user.google_token !== "" ? (
            callsForSelectedDay.length > 0 ? (
              callsForSelectedDay.map((call, index) => (
                <CallItem key={index}>
                  <Time>{format(new Date(call.meeting_time), "h:mm a")}</Time>
                  <CallInfo>
                    <div>{call.meeting_name}</div>
                    <div>{call.host}</div>
                  </CallInfo>
                  {call.deal_id && call.score_id ? (
                    <div className="deal-details">
                      <div className="deal-name">{dealIdToName(call.deal_id)}</div>
                      <GreenButton
                        width={"120px"}
                        height={36}
                        fontSize={12}
                        onClick={() => navigate(`/app/deals/${call.deal_id}`)}
                      >
                        Deal Details
                      </GreenButton>
                    </div>
                  ) : (
                    <>
                      {call.record && call.has_bot_authority && !call.score_id ? (
                        <div className="dropdown-wrapper">
                          <DropDown
                            value={
                              call.new_deal ? "New Deal" : call.deal_id || ""
                            }
                            onChange={(event) => handleDealChange(index, event)}
                          >
                            <option value="" disabled selected>
                              Select Deal
                            </option>
                            <option value="New Deal">New Deal</option>
                            {deals &&
                              deals.map((deal: QuickDeal, index: number) => (
                                <option key={index} value={deal.id}>
                                  {deal.name}
                                </option>
                              ))}
                          </DropDown>
                        </div>
                      ) : (
                        <>{call.deal_id && <div className="deal-name">{dealIdToName(call.deal_id)}</div>}</>
                      )}
                      {(call.deal_id === "New Deal" || call.new_deal) && (
                        <>
                          {!call.editing ? (
                            <div className="website-wrapper">
                              <p>{call.website || ""}</p>
                              <div onClick={() => handleEdit(index)}>
                                <Edit />
                              </div>
                            </div>
                          ) : (
                            <div className="input-wrapper">
                              <input
                                type="text"
                                placeholder="www.companywebsite.com"
                                className={!call.website ? "empty" : ""}
                                value={call.website || ""}
                                onChange={(e) => updateWebsite(index, e)}
                              />
                              <div
                                className="checkmark"
                                onClick={(e) =>
                                  submitWebsite(index, call.website || "")
                                }
                              >
                                &#x2713;
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div
                        className={"switch-wrapper"}
                      >
                        <p>Record:</p>
                        <ToggleSwitch
                          checked={call.record}
                          onChange={() =>
                            handleToggle(selectedDay, index, call)
                          }
                        />
                      </div>
                    </>
                  )}
                  <Host>
                    <HostInitial>
                      {call?.host?.charAt(0).toLocaleUpperCase()}
                    </HostInitial>
                    <p>{call.host}</p>
                  </Host>
                </CallItem>
              ))
            ) : (
              <CallItem>
                <Time></Time>
                <CallInfo>
                  <div className="no-meetings">
                    No meetings scheduled on this day.
                  </div>
                </CallInfo>
                <Host>
                  <HostInitial>NA</HostInitial>
                </Host>
              </CallItem>
            )
          ) : (
            <CallItem>
              <Time></Time>
              <CallInfo>
                <div className="no-meetings">No calendar connected</div>
              </CallInfo>
              <div className="deal-details">
                <GreenButton
                  width={"160px"}
                  height={36}
                  fontSize={12}
                  onClick={() => navigate(`/app/settings/integrations`)}
                >
                  Connect Calendar
                </GreenButton>
              </div>
            </CallItem>
          )}
        </CallsContainer>
      )}
    </CalendarWrapper>
  );
};
export default Calendar;
