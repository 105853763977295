import {
  FC,
  FormEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  dealEvaluationFileUploadPost,
  dealEvaluationPost,
  postPrecallPrep,
} from "api/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getDealsQuick, getDeals } from "api/helpers";
import {
  startLoading,
  endLoading,
  setLoadingText,
  setCallModal,
  setAddDealModal,
  setLoadingSubText,
} from "state/appActions";
import { DropDown } from "shared/shared_styled_comps/forms.styled";
import { QuickDeal, Deal } from "types";
import Arrow from "static/svgs/Arrow";
import {
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import {
  AddDealWrapper,
  AddDealForm,
  InputWrapper,
  Input,
  Label,
  CalendarInput,
  TranscriptInput,
  DealOrCall,
} from "./AddDeal.styled";

interface AddDealProps {
  setModal: Function;
  currentDealId?: string;
  tourStep?: number;
  setTourStep?: Dispatch<SetStateAction<number>>;
  setTourIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const AddDeal: FC<AddDealProps> = ({
  setModal,
  currentDealId,
  tourStep,
  setTourStep,
  setTourIsOpen,
}) => {
  const [dealName, setDealName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPosition, setContactPosition] = useState("");
  const [website, setWebsite] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const stages = useSelector((state: any) => state.app.stages);
  const user = useSelector((state: any) => state.auth.user);
  let dispatch = useDispatch();
  const [dealStage, setDealStage] = useState(stages[0]);
  const [callDate, setCallDate] = useState("");
  const [dealId, setDealId] = useState("");
  const [deals, setDeals] = useState([]);
  const [defaultDeal, setDefaultDeal] = useState<Deal | {}>({});
  const [transcript, setTranscript] = useState("");
  const [upload, setUpload] = useState(true);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [error, setError] = useState("Please fill out all form fields");
  const [modalStep, setModalStep] = useState(0); // 0 is the choice modal, 1 is add deal, 2 is add call
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileType =
        fileNameParts.length > 1 ? fileNameParts.pop()?.toLowerCase() : "";
      if (
        fileType === "pdf" ||
        fileType === "docx" ||
        fileType === "doc" ||
        fileType === "txt"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a PDF, DOCX, DOC, or TXT file.");
      }
    }
  };

  const fetchDealsData = async () => {
    dispatch(setLoadingText("Fetching your active deals"));
    dispatch(startLoading());
    let response = await getDealsQuick();
    dispatch(endLoading());
    // Ensure to on ly display 10 at a time
    setDeals(response);
    // Set to current deal you are on if you're on a specific deal page
    if (pathname.includes('deals/')) {
      // pull off the deal idea from the end of the pathname
      const dealId = pathname.split('/').pop();
      if (dealId) {
        setDealId(dealId)
      }
    }

  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
    const selectedFile = event.dataTransfer.files?.[0];
    if (selectedFile) {
      if (selectedFile) {
        const fileNameParts = selectedFile.name.split(".");
        const fileType =
          fileNameParts.length > 1 ? fileNameParts.pop()?.toLowerCase() : "";
        if (
          fileType === "pdf" ||
          fileType === "docx" ||
          fileType === "doc" ||
          fileType === "txt"
        ) {
          setFile(selectedFile);
        } else {
          alert("Please select a PDF, DOCX, DOC, or TXT file.");
        }
      }
    }
  };

  function isValidURL(url: string) {
    var urlRegex = /^(https?:\/\/)?www\.[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,})+$/;
    return urlRegex.test(url);
  }

  const validator = () => {
    if (modalStep === 2) {
      if (!dealId) {
        setError("Please select deal to add a call to.");
        return false;
      }
      if (!transcript) {
        setError("Please provide call data.");
        return false;
      }
      if (!callDate) {
        setError("Please provide call date.");
        return false;
      }
    } else {
      if (!dealName) {
        setError("Please provide a name for the new deal.");
        return false;
      }
      if (!transcript && !website) {
        setError(
          "Please provide call data and/or a company website to create a deal."
        );
        return false;
      }
      // if (website && !isValidURL(website)) {
      //   setError("Please enter a valid URL for the company website.")
      //   return false
      // }
    }
    return true;
  };

  const handlePrecallPrep = async (validWebsite: string) => {
    try {
      let precallResponse = await postPrecallPrep({
        dealName,
        url: validWebsite,
        chain_type: "refine", // Options are 'stuff', 'refine', 'map_reduce'
        save: true,
      });
      if (precallResponse.status === "success") {
        return precallResponse.output.deal_id;
      } else {
        setError("An unknown error occured. Please refresh and try again.");
        return;
      }
    } catch {
      setError("An unknown error occured. Please refresh and try again.");
      return;
    }
  };

  const handleEval = async (deal_id: string, fileUpload: boolean, validWebsite: string) => {
    let data;
    if (fileUpload && file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("call_date", callDate);
      formData.append("deal_name", dealName);
      formData.append("deal_stage", dealStage);
      formData.append("deal_id", deal_id);
      try {
        let evalResponse = await dealEvaluationFileUploadPost(formData);
        if (evalResponse.deal_id) {
          return evalResponse.deal_id;
        } else {
          setError("An unknown error occured. Please refresh and try again.");
          return;
        }
      } catch {
        setError("An unknown error occured. Please refresh and try again.");
        return;
      }
    } else {
      if (deal_id) {
        data = {
          transcript: transcript,
          deal_stage: dealStage,
          deal_id,
          call_date: callDate,
          url: validWebsite
        };
      } else {
        data = {
          transcript: transcript,
          deal_stage: dealStage,
          deal_name: dealName,
          call_date: callDate,
          url: validWebsite
        };
      }
      try {
        let evalResponse = await dealEvaluationPost(data);
        if (evalResponse.deal_id) {
          return evalResponse.deal_id;
        } else {
          setError("An unknown error occured. Please refresh and try again.");
          return;
        }
      } catch {
        setError("An unknown error occured. Please refresh and try again.");
        return;
      }
    }
  };

  const handleDealSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    dispatch(
      setLoadingText("Deal Coach is processing - time for a quick break!")
    );
    dispatch(
      setLoadingSubText(
        "Feel free to close out of this modal. You will receive an email and notification once this deal evaluation processes, and a refresh of the Deals page will show it once complete."
      )
    );

    let deal_id = "";
    if (
      !user?.completed_intro_tour &&
      defaultDeal &&
      setTourIsOpen &&
      setTourStep
    ) {
      setModal(false);
      setTourIsOpen(false);
      setTourStep(4);
      navigate("/app/deals");
      dispatch(endLoading());
    } else {
      // Validate all the input fields
      if (!validator()) {
        return;
      }
      if (transcript || file) {
        // For adding a call, pass the state dealId
        if (modalStep == 2) {
          dispatch(startLoading());
          await handleEval(dealId, false, "");
          deal_id = dealId;
          dispatch(endLoading());
        } else {
          // Run precall prep if needed
          if (website) {
            dispatch(startLoading());
            let validWebsite;
            if (!website.includes("https://")) {
              validWebsite = "https://" + website;
            } else {
              validWebsite = website;
            }
            // deal_id = await handlePrecallPrep(validWebsite);

            if (file) {
              deal_id = await handleEval(deal_id, true, validWebsite);
            } else {
              deal_id = await handleEval(deal_id, false, validWebsite);
            }
          } else {
            setError("Please add a website for the company.");
            return;
          }
        }
      } else {
        setError("Please add a transcript for the call.");
        dispatch(endLoading());
        return;
      }

      if (deal_id) {
        setModal(false);
        dispatch(endLoading());
        navigate(`/app/deals/${deal_id}`);
      } else {
        setError("An unknown error occured. Please refresh and try again.");
        dispatch(endLoading());
      }
    }
  };

  useEffect(() => {
    const setDefaultDeal = () => {
      setDealName("GoPro");
      setWebsite("www.gopro.com");
      setCallDate("2024-07-01");
    };
    if (!user?.completed_intro_tour) {
      setDefaultDeal();
    }
  }, []);

  // const handleDealSubmit = async (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault()
  //   setError("")
  //   // Validate all the input fields

  //   dispatch(
  //     setLoadingText("Deal Coach is processing - time for a quick break!")
  //   )
  //   dispatch(startLoading())
  //   let deal_id
  //   // Run precall prep if needed
  //   if (!validator()) {
  //     return
  //   }
  //   if (website) {
  //     let validWebsite
  //     if (!website.includes("https://")) {
  //       validWebsite = "https://" + website
  //     } else {
  //       validWebsite = website
  //     }
  //     deal_id = await handlePrecallPrep(validWebsite)
  //   }
  //   if (transcript) {
  //     // For adding a call, pass the state dealId
  //     if (modalStep == 2) {
  //       await handleEval(dealId)
  //       deal_id = dealId
  //     } else {
  //       deal_id = await handleEval(deal_id)
  //     }
  //   }
  //   if (deal_id) {
  //     setModal(false)
  //     dispatch(endLoading())
  //     if (!user?.completed_intro_tour && setTourStep && setTourIsOpen) {
  //       setTourIsOpen(false)
  //       setTourStep(4)
  //       navigate("/app/deals/")
  //     } else {
  //       navigate(`/app/deals/${deal_id}`)
  //     }
  //   } else {
  //     setError("An unknown error occured. Please refresh and try again.")
  //     dispatch(endLoading())
  //   }
  // }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  useEffect(() => {
    setError("");
    if (modalStep == 2) {
      setUpload(true);
      fetchDealsData();
    }
  }, [modalStep]);

  useEffect(() => {
    setModalStep(0);
    if (currentDealId) {
      setDealId(currentDealId);
      setModalStep(2);
    }
  }, []);

  const handleNav = () => {
    navigate("/app/settings/integrations");
    dispatch(setAddDealModal(false));
  };

  const handleModalStep = (selectedModalStep: number) => {
    if (
      !user?.completed_intro_tour &&
      setTourStep &&
      tourStep &&
      setTourIsOpen
    ) {
      if (selectedModalStep === 1) {
        setTourStep(3);
        setModalStep(selectedModalStep);
      }
      if (selectedModalStep === 2) {
        setTourStep(5);
        setModalStep(selectedModalStep);
      }
    } else {
      setModalStep(selectedModalStep);
    }
  };

  switch (modalStep) {
    case 0:
      return (
        <DealOrCall className="deal-or-call">
          <h2>What do you want to do?</h2>
          <GradientButton
            onClick={() => handleModalStep(2)}
            width="80%"
            height="60px"
            fontSize={14}
            top={230}
            disabled={!user?.completed_intro_tour}
          >
            Add A Call To An&nbsp;<GradientText> Existing Deal</GradientText>
          </GradientButton>
          <GradientButton
            onClick={() => handleModalStep(1)}
            width="80%"
            height="60px"
            fontSize={14}
            top={150}
          >
            Add A New&nbsp;<GradientText>Deal</GradientText>
          </GradientButton>
          {Boolean(
            user && user.zoom_access_token && user.zoom_access_token !== ""
          ) || Boolean(user.microsoft_token && user.microsoft_token !== "") ? (
            <GradientButton
              onClick={() => dispatch(setCallModal(true))}
              width="80%"
              height="60px"
              fontSize={14}
              top={70}
            >
              Import calls from&nbsp;
              <GradientText>
                {Boolean(
                  user &&
                    user.zoom_access_token &&
                    user.zoom_access_token !== ""
                )
                  ? "Zoom"
                  : "Teams"}
              </GradientText>
            </GradientButton>
          ) : (
            <GradientButton
              onClick={() => handleNav()}
              width="80%"
              height="60px"
              fontSize={14}
              top={70}
            >
              Connect&nbsp;<GradientText>Call Tool</GradientText>
            </GradientButton>
          )}
        </DealOrCall>
      );
    case 1:
      return (
        <AddDealWrapper className="new-deal">
          <div onClick={() => setModalStep(0)} className="svg-wrapper">
            <Arrow />
          </div>
          <h2>New Deal</h2>
          {error && <p className="error">{error}</p>}
          <AddDealForm onSubmit={(event) => handleDealSubmit(event)}>
            <InputWrapper>
              <Label>Deal Name*</Label>
              <Input
                type="text"
                placeholder="Microsoft Deal"
                value={dealName}
                onChange={(e) => setDealName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Company Website</Label>
              <Input
                type="text"
                placeholder="www.microsoft.com"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </InputWrapper>
            {/* <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>Lead Contact Full Name</Label>
                <Input
                  type="text"
                  placeholder="Full name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>Contact Title</Label>
                <Input
                  type="text"
                  placeholder="Contact title"
                  value={contactPosition}
                  onChange={(e) => setContactPosition(e.target.value)}
                />
              </InputWrapper>
            </div> */}
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>Deal Stage*</Label>
                <DropDown
                  value={dealStage}
                  onChange={(e) => setDealStage(e.target.value)}
                >
                  {stages.map((stage: string, index: number) => (
                    <option key={index} value={stage}>
                      {stage}
                    </option>
                  ))}
                </DropDown>
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>Call Date</Label>
                <CalendarInput
                  type="date"
                  id="calendar"
                  name="calendar"
                  value={callDate}
                  onChange={(e) => setCallDate(e.target.value)}
                />
              </InputWrapper>
            </div>
            <div className="upload-wrapper">
              <div className="switch-wrapper">
                <p onClick={() => setUpload(true)}>File upload</p>
                &nbsp;/&nbsp;
                <p onClick={() => setUpload(false)}>Paste Transcript</p>
              </div>
              {upload ? (
                <div
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  className={`drag-and-drop ${
                    isDraggingOver ? "drag-over" : ""
                  }`}
                >
                  Drag or drop a new file
                  <p>(pdf, doc, docx, txt files supported)</p>
                  {file && (
                    <div className="selected-file">
                      <p>Selected File: {file.name}</p>
                    </div>
                  )}
                  <div>
                    <input
                      onChange={handleFileChange}
                      type="file"
                      id="selectedFile"
                      accept=".pdf,.docx,.doc,.txt"
                      style={{ display: "none" }}
                    />
                    <input
                      className="upload-button"
                      type="button"
                      value="Upload"
                      onClick={() =>
                        document?.getElementById("selectedFile")?.click()
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="text-area-wrapper">
                  <InputWrapper>
                    <Label>Transcript</Label>
                    <TranscriptInput
                      placeholder="Paste transcript here..."
                      value={transcript}
                      onChange={(e) => setTranscript(e.target.value)}
                    />
                  </InputWrapper>
                </div>
              )}
            </div>
            <GradientButton
              type="submit"
              width="80%"
              height="40px"
              fontSize={14}
              top={50}
            >
              <GradientText>Add Deal</GradientText>
            </GradientButton>
          </AddDealForm>
        </AddDealWrapper>
      );
    case 2:
      return (
        <AddDealWrapper>
          <div onClick={() => setModalStep(0)} className="svg-wrapper">
            <Arrow />
          </div>
          <h2>Add Call</h2>
          {error && <p className="error">{error}</p>}
          <AddDealForm onSubmit={(event) => handleDealSubmit(event)}>
            <InputWrapper>
              <Label>Deal Name*</Label>
              <DropDown
                value={dealId}
                onChange={(e) => setDealId(e.target.value)}
              >
                <option value={""}>
                  Select a deal
                </option>
                {deals &&
                  deals.map((deal: QuickDeal, index: number) => (
                    <option key={index} value={deal.id}>
                      {deal.name}
                    </option>
                  ))}
              </DropDown>
            </InputWrapper>
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>Deal Stage*</Label>
                <DropDown
                  value={dealStage}
                  onChange={(e) => setDealStage(e.target.value)}
                >
                  {stages.map((stage: string, index: number) => (
                    <option key={index} value={stage}>
                      {stage}
                    </option>
                  ))}
                </DropDown>
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>Call Date*</Label>
                <CalendarInput
                  type="date"
                  id="calendar"
                  name="calendar"
                  value={callDate}
                  onChange={(e) => setCallDate(e.target.value)}
                />
              </InputWrapper>
            </div>
            <div className="switch-wrapper">
              <p onClick={() => setUpload(true)}>File upload</p>
              &nbsp;/&nbsp;
              <p onClick={() => setUpload(false)}>Paste Transcript</p>
            </div>
            {upload ? (
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                className={`drag-and-drop ${isDraggingOver ? "drag-over" : ""}`}
              >
                Drag or drop a new file
                <p>(pdf, doc, docx, txt files supported)</p>
                {file && (
                  <div className="selected-file">
                    <p>Selected File: {file.name}</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-area-wrapper">
                <InputWrapper>
                  <Label>Message</Label>
                  <TranscriptInput
                    placeholder="Paste transcript here..."
                    value={transcript}
                    onChange={(e) => setTranscript(e.target.value)}
                  />
                </InputWrapper>
              </div>
            )}
            <GradientButton
              type="submit"
              width="80%"
              height="60px"
              fontSize={14}
              top={50}
            >
              <GradientText>Upload</GradientText>
            </GradientButton>
          </AddDealForm>
        </AddDealWrapper>
      );
    default:
      return <></>;
  }
};

export default AddDeal;
