import { colors } from "colors";
import styled, { keyframes } from "styled-components";

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WeekNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const DayButton = styled.button<{ selected: boolean }>`
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  background-color: ${({ selected }) => (selected ? "#e8f0fe" : "white")};
  cursor: pointer;
  border: none;
  border-radius: 5px;
`;

export const CallsContainer = styled.div`
  padding: 20px 0;
  border-top: 1px solid #ddd;
  overflow-y: auto;
  max-height: 300px;
  .checkmark {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.mainGreen};
    border-radius: 5px;
    margin-left: 15px;
  }
  .dropdown-wrapper {
    margin-right: 20px;
    select {
      background-color: transparent;
      color: ${colors.black};
      box-shadow: none;
      border: 1px solid ${colors.black};
    }
  }
  .deal-name {
    color: ${colors.black};
    margin-right: 20px;
  }
  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    flex-wrap: nowrap;
    width: 185px;
    .empty {
      border: 1px solid red;
    }
  }
  .website-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 185px;
    margin-right: 20px;
    color: ${colors.black};
    p {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    svg {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
      margin-left: 3px;
      path {
        fill: ${colors.black};
      }
    }
  }
`;

export const CallItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 1px solid #ddd;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 5px;
  .deal-details {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .switch-wrapper {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    p {
      margin-right: 10px;
    }
  }
`;

export const Time = styled.div`
  width: 100px;
  color: #555;
`;

export const CallInfo = styled.div`
  flex: 1;
  color: ${colors.black};
  .no-meetings {
    font-size: 20px;
  }
`;

export const Host = styled.div`
  display: flex;
  align-items: center;
  color: #555;
  width: 20%;
  justify-content: center;
  margin-left: 15px;
  p {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
  }
`;

export const HostInitial = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-color: #f00;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const WeekToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: fit-content;
  margin-left: -11px;
  margin-top: 10px;
  .week-title {
    font-size: 20px;
  }
`;

export const ToggleButton = styled.button`
  background: none;
  border: none;
  svg {
    width: 20px;
    height: 20px;
  }
  cursor: pointer;
  color: ${colors.white};
  transform: rotate(90deg);
  &.right {
    transform: rotate(-90deg);
  }
`;

const skeletonLoading = keyframes`
0% {
  background-position: -1800px 0;
}
100% {
  background-position: 1000px 0;
}
`;

export const CalendarLoader = styled.div`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 55px;
  height: 300px;
  background: radial-gradient(
    farthest-side,
    ${colors.deepGray} 0%,
    #c0c0c0 -54%,
    ${colors.deepGray} 75%
  );
  background: linear-gradient(
    90deg,
    ${colors.deepGray} 25%,
    #7b7b7b 50%,
    ${colors.deepGray} 75%
  );
  animation: ${skeletonLoading} 2s infinite;
  background-repeat: no-repeat;
  background-size: 166% 80%; /* Stretch horizontally */
  background-position: left center;
`;
