import { FC, useState, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "auth/authActions";
import axios, { AxiosError } from "axios";
import { startLoading, endLoading, setError } from "state/appActions";
import { Dispatch } from "redux";
import { useNavigate, useLocation } from "react-router-dom";
import LogoWithText from "static/svgs/LogoWithText";
import QuickPitch from "components/QuickPitch/QuickPitch";
import { getUser, getGoogleURL, getZoomURL } from "api/helpers";
import { GetUser } from "types";
import { setUserData } from "auth/authActions";
import Google from "static/svgs/Google";
import { useParams } from "react-router-dom";
import Zoom from "static/svgs/Zoom";
import { login as loginPost } from "api/helpers";
import {
  LoginInput,
  LoginLabel,
  SquareButton,
} from "shared/shared_styled_comps/forms.styled";
import {
  LoginWrapper,
  LoginForm,
  FormWrapper,
  ContentWrapper,
  OAuthWrappr,
  LogoWrapper,
  OAuthLink,
} from "./Login.styled";

let unescape = window.unescape || window.decodeURI;

interface LoginProps {}

const loginAsync = async (
  username: string,
  password: string,
  dispatch: Dispatch
) => {
  const data = {
    username,
    password,
  };
  // Make the login request
  const response = await loginPost(data);
  if (axios.isAxiosError(response)) {
    // Handle AxiosError
    setError(response?.response?.data?.message);
    return { success: false, message: response?.response?.data?.message };
  } else {
    if (response?.data?.status === "success") {
      dispatch(login());
      let data: GetUser = await getUser();
      dispatch(setUserData(data.user));
      return { success: true };
    }
    return { success: false };
  }
};

const Login: FC<LoginProps> = () => {
  const location = useLocation()
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { sessiontimeout } = useParams();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [unauthorizedModal, setUnauthorizedModal] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");

  // Handles Auth
  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    setUnauthorizedModal(false);
    let response = await loginAsync(
      email,
      password,
      dispatch
    );
    if (!response.success) {
      if (response.message) {
        setError(response.message);
      } else {
        setError("Login failed for an unknown reason.");
      }
    }
    dispatch(endLoading());
    if (response.success) {
      if (redirect) {
        navigate(redirect)
      } else {
        navigate("/app/deals")
      }
    }
  };

  const handleOAuthLogin = async (type: string) => {
    dispatch(startLoading());
    let response;
    switch (type) {
      case "google":
        response = await getGoogleURL();
        break;
      case "zoom":
        response = await getZoomURL();
        break;
    }
    if (response) {
      window.location.assign(response.redirect_url);
    } else {
      dispatch(endLoading());
      setError(
        `Error loading the ${type} authenticator. Please refresh and try again`
      );
    }
  };

  useEffect(() => {
    if (params.get("sessiontimeout")) {
      setError(
        "Your session has timed out for security reasons. Please sign in again."
      );
    }
  }, []);

  return (
    <LoginWrapper>
      <FormWrapper>
        <LogoWrapper>
          <h1 style={{ textAlign: "center" }}>Login</h1>
        </LogoWrapper>
        <OAuthWrappr>
          <OAuthLink onClick={() => handleOAuthLogin("google")}>
            <Google />
            Log in with Google
          </OAuthLink>
          {/* <OAuthLink onClick={() => handleOAuthLogin("zoom")}>
            <Zoom />
            Log in with Zoom
          </OAuthLink> */}
        </OAuthWrappr>
        <div className="horizontal-divider">
          <div className="line"></div>
          <div className="or-text">or</div>
          <div className="line"></div>
        </div>
        <LoginForm onSubmit={(e) => handleLogin(e)}>
          <LoginLabel>
            Username / Email:
            <LoginInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </LoginLabel>
          <LoginLabel>
            Password:
            <LoginInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </LoginLabel>
          <SquareButton type="submit">Log In</SquareButton>
          {error && <p className="error">{error}</p>}
          <p onClick={() => navigate("/forgot-password")} className="forgot">Forgot password?</p>
        </LoginForm>
      </FormWrapper>
      <ContentWrapper>
        <QuickPitch button navigate={navigate} />
      </ContentWrapper>
    </LoginWrapper>
  );
};

export default Login;
