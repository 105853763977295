import { colors } from "colors";
import styled from "styled-components";

export const CompanyBreakdownWrapper = styled.div`
  padding: 40px;
  padding-top: 10px;
  height: 92%;
  overflow-y: auto;
  .bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .info {
      width: 45%;
    }
    p {
      color: ${colors.lightGray};
      margin: 0;
      margin-bottom: 4px;
      font-size: 14px;
    }
    strong {
      color: ${colors.white};
    }
  }
  h1 {
    span {
      color: ${colors.lightGray};
      font-size: 14px;
      margin-left: 20px;
    }
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: ${colors.mainGreen};
  }
  .regen-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 80px;
      height: 20px;
      border: none;
      border-radius: 10px;
      font-size: 8.5px;
      color: ${colors.black};
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        path {
          fill: ${colors.black};
        }
      }
    }
    p {
      width: 50%;
      font-size: 11px;
      color: ${colors.lightGray};
    }
  }
`;

interface DealCoachCardProps {
  inverse?: boolean;
}

export const DealCoachCard = styled.div<DealCoachCardProps>`
  width: 360px;
  height: 65px;
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 9px 20px 0px #0000001a, 0px 37px 37px 0px #00000017,
    0px 84px 50px 0px #0000000d, 0px 149px 59px 0px #00000003,
    0px 232px 65px 0px #00000000;
  &:hover {
    transform: scale(1.05);
  }
  p {
    color: ${colors.black} !important;
    margin: 0;
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 15px;
  }
  ${(props) =>
    props.inverse &&
    `background-color: ${colors.black};  p {
    color: ${colors.white} !important;;
  }`}
  svg {
    height: 12px;
    width: 12px;
    margin-right: 4px;
    path {
      fill: ${colors.mainGreen};
    }
  }
  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    p {
      color: ${colors.mainGreen} !important;
      font-weight: 700;
      font-size: 11px !important;
      margin: 0;
    }
  }
`;

export const ContactCard = styled.div`
  padding: 30px;
  box-shadow: 0px 9px 20px 0px #0000001a, 0px 35px 35px 0px #00000017,
    0px 80px 48px 0px #0000000d, 0px 142px 57px 0px #00000003,
    0px 222px 62px 0px #00000000;
  height: 150px;
  width: 330px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-bottom: 7px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
  }
  .website {
    margin-top: 20px;
  }
`;
