import { colors } from "colors";
import styled from "styled-components";

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 30px;
  overflow-y: auto;
  height: 652px;
  padding-right: 20px;
  .letter-wrapper {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    border-radius: 100%;
    background-color: ${colors.mainGreen};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
  }
  .child-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .line {
    width: 2px;
    background-color: #ccc;
    height: 60px;
    min-height: 30px;
    margin-left: 20px;
    margin-right: 20px; /* Space between line and comment content */
  }
`;

export const CommentsSection = styled.div`
  margin-left: 30px;
  min-width: 400px;
  padding: 0px 10px;
  box-shadow: -11px 18px 55px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  ::-webkit-scrollbar {
    height: 14px;
    width: 14px;
    background: ${colors.middleGray};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.black};
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #000;
  }

  ::-webkit-scrollbar-track {
    height: 100px; /* Fixed height of the scrollbar track */
  }

  h4 {
    text-align: center;
    margin-top: 40px;
  }
`;

export const Comment = styled.div`
  display: flex;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  &:hover {
    .actions-wrapper {
      display: flex;
    }
  }
  &.child {
    &:before {
      left: 30px; /* Adjust line position */
      background-color: #999; /* Optional: different color for child lines */
    }
  }
`;

export const VoteSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`;

export const VoteButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  color: #555;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.2rem 0.6rem;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const VoteCount = styled.div`
  margin: 0.5rem 0;
  font-weight: bold;
`;

export const CommentBody = styled.div`
  flex: 1;
  position: relative;
`;

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  .actions-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    position: absolute;
    right: 0;
  }
  .reply-wrapper {
    font-size: 12px;
    color: #007bff;
    cursor: pointer;
    margin-right: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
  .delete-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    cursor: pointer;
    font-size: 12px;
    svg {
      width: 17px;
      height: 17px;
      path {
        stroke: red;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const Username = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
  color: black;
  margin-left: 8px;
`;

export const Time = styled.span`
  color: #888;
  margin-right: auto;
  position: absolute;
  font-size: 12px;
  right: -5px;
  top: -10px;
`;

export const ReplyButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const CommentContent = styled.div`
  color: #555;
`;

export const UserList = styled.ul`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 150px;
  max-height: 200px;
  overflow-y: auto;
  bottom: 59px;
  left: 43px;
  z-index: 1000;
  color: ${colors.black};
`;

export const UserListItem = styled.li<{highlighted: boolean}>`
  padding: 5px 10px;
  cursor: pointer;
  background-color: ${({ highlighted }) => (highlighted ? colors.lightGray : 'transparent')};
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const MentionSpan = styled.span`
  color: blue;
`;
