import styled from "styled-components";

export const TranscriptWrapper = styled.div`
  width: 100%;
  .search-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: -10px;
    button {
      margin-left: 66px;
    }
    .search-bar {
      width: 70%;
    }
  }
  .transcript-content {
    height: 540px;
    overflow-y: scroll;
    padding-right: 45px;
  }
  .highlight {
    background-color: yellow;
    color: black;
    transition: background-color 0.5s ease;
  }
`;
