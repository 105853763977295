import React, { FC, useState } from "react";
import { AdminLoginWrapper } from "./AdminLogin.styled";
import { startLoading, endLoading } from "state/appActions";
import { adminLogin, getUser } from "api/helpers";
import axios from "axios";
import { Dispatch } from "redux";
import {
  LoginInput,
  LoginLabel,
  SquareButton,
} from "shared/shared_styled_comps/forms.styled";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetUser } from "types";
import { login, setUserData } from "auth/authActions";
import { MarketingPageWrapper } from "shared/shared_styled_comps/components.styled";

interface AdminLoginProps {}

const AdminLogin: FC<AdminLoginProps> = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const loginAsync = async (
    username: string,
    password: string,
    dispatch: Dispatch
  ) => {
    const data = {
      username,
      password,
    };
    // Make the login request
    const response = await adminLogin(data);
    if (axios.isAxiosError(response)) {
      // Handle AxiosError
      setError(response?.response?.data?.message);
      return { success: false, message: response?.response?.data?.message };
    } else {
      if (response?.data?.message === "success") {
        dispatch(login());
        let data: GetUser = await getUser();
        dispatch(setUserData(data.user));
        return { success: true };
      }
      return { success: false };
    }
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    let response = await loginAsync(email, password, dispatch);
    if (!response.success) {
      if (response.message) {
        setError(response.message);
      } else {
        setError("Login failed for an unknown reason.");
      }
    }
    dispatch(endLoading());
    if (response.success) {
      navigate("/impersonate");
    }
  };

  return (
    <MarketingPageWrapper>
      <AdminLoginWrapper>
        <h1>Login</h1>
        <form style={{ width: "100%" }} onSubmit={(e) => handleLogin(e)}>
          <LoginLabel>
            Username / Email:
            <LoginInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </LoginLabel>
          <LoginLabel>
            Password:
            <LoginInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </LoginLabel>
          <SquareButton type="submit">Log In</SquareButton>
          {error && <p className="error">{error}</p>}
        </form>
      </AdminLoginWrapper>
    </MarketingPageWrapper>
  );
};

export default AdminLogin;
