import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import { getDeals, deleteDeal } from "api/helpers";
import moment from "moment";
import { Category } from "types";
import { ScoreBox } from "components/DealPage/DealPage.styled";
import { ScoreBox as TotalScoreBox } from "components/DealSummary/DealSummary.styled";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import {
  GreenButton,
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import Paginate from "components/reusable/Paginate/Paginate";
import {
  Deal,
  DealsWrapper,
  DealsHeader,
  DealsTable,
  DealLoad,
  SearchWrapper,
  DealsLoadWrapper,
  ToggleButton,
  ToggleContainer,
  ToggleBackground,
} from "./DealsPage.styled";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import Filter from "static/svgs/Filter";
import { Deal as DealDetails, CategoryDefault } from "types";
import Trash from "static/svgs/Trash";
import Refresh from "static/svgs/Refresh";

interface DealsPageProps {
  tourStep: number;
  setTourStep: Dispatch<SetStateAction<number>>;
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultScores = [
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "M",
    highest_score: 0,
  },
  {
    category_name: "E",
    highest_score: 0,
  },
  {
    category_name: "D",
    highest_score: 0,
  },
  {
    category_name: "D",
    highest_score: 0,
  },
  {
    category_name: "P",
    highest_score: 0,
  },
  {
    category_name: "I",
    highest_score: 0,
  },
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "V",
    highest_score: 0,
  },
];

const DealsPage: FC<DealsPageProps> = ({
  tourStep,
  setTourStep,
  setTourIsOpen,
}) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const isLoading = useSelector((state: any) => state.app.isLoading);
  const [activeOption, setActiveOption] = useState<"my-deals" | "team-deals">(
    "my-deals"
  );

  const [data, setData] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [direction, setDirection] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDeals, setTotalDeals] = useState(1);
  const tipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      Object.keys(user).length !== 0 &&
      !user.completed_intro_tour &&
      tourStep === 0
    ) {
      navigate("/app/dashboard");
    }
  }, [user]);

  const tableHeaders: string[] = [
    "Score",
    "Deal Name",
    "Lead",
    "Last Call",
    "Stage",
    "Categories",
  ];

  const fetchDealsData = async () => {
    dispatch(startLoading());
    let data = {
      sortBy: sortBy,
      sortOrder: direction ? "desc" : "asc",
      limit: 10,
      page: activePage,
      filterBy: "",
      searchBy: searchValue,
      orgDeals: activeOption === "team-deals",
    };
    let response = await getDeals(data);
    dispatch(endLoading());
    if (user && !user?.completed_intro_tour && tourStep > 0) {
      setTourIsOpen(true);
    }
    setTotalPages(response?.total_pages);
    setTotalDeals(response?.total_deals);
    // Ensure to on ly display 10 at a time
    setData(response?.deals.slice(0, 10));
  };

  const deleteDealChoice = async (deal_id: string) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this deal? All deal data will be deleted for good."
    );
    if (userConfirmed) {
      dispatch(startLoading());
      let response = await deleteDeal(deal_id);
      dispatch(endLoading());
      window.location.reload();
    } else {
      return;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(user).length !== 0) {
      fetchDealsData();
    }
  }, [activePage, sortBy, direction, user, activeOption]);

  const updateSort = (header: string) => {
    if (header === "Stage") {
      header = "deal_stage";
    } else if (header === "Deal Name") {
      header = "deal_name";
    } else if (header === "Score") {
      header = "total_score";
    }
    if (sortBy === header) {
      setDirection(!direction);
    }
    setSortBy(header);
    console.log(`header: ${header}`);
    console.log(`direct: ${direction ? "desc" : "asc"}`);
  };

  const handleDealDetailsNavigation = (deal_id: string) => {
    if (!user?.completed_intro_tour) {
      setTourStep(6);
      setTourIsOpen(false);
      navigate(`/app/deals/${deal_id}`);
    } else {
      navigate(`/app/deals/${deal_id}`);
    }
  };

  return (
    <PageWrapper pageTitle="Deals" className="deals-page">
      {/* <SearchWrapper>
        <SearchBar
          value={searchValue}
          updateFunction={setSearchValue}
        ></SearchBar>
      </SearchWrapper> */}
      <ToggleContainer>
        <ToggleBackground activeOption={activeOption} />
        <ToggleButton
          active={activeOption === "my-deals"}
          onClick={() => setActiveOption("my-deals")}
        >
          My Deals
        </ToggleButton>
        <ToggleButton
          active={activeOption === "team-deals"}
          onClick={() => setActiveOption("team-deals")}
        >
          Team Deals
        </ToggleButton>
      </ToggleContainer>
      <DealsWrapper>
        <DealsTable>
          <DealsHeader>
            <tr>
              {tableHeaders.map((header: string, index: number) => (
                <th
                  key={index}
                  className={
                    header === "Score" ? "title-wrapper score" : "title-wrapper"
                  }
                >
                  <p>
                    {header}
                    <div
                      className="filter-wrapper"
                      onClick={() => updateSort(header)}
                    >
                      {header !== "Categories" && <Filter />}
                    </div>
                  </p>
                </th>
              ))}
            </tr>
          </DealsHeader>
          <hr />
          <tbody>
            {data ? (
              data?.map((deal: DealDetails, index: number) => {
                return (
                  <>
                    <Deal
                      key={index}
                      className={deal.processing ? "processing" : ""}
                    >
                      <td className="score">
                        <TotalScoreBox score={deal.overall_deal_score / 10}>
                          {deal.overall_deal_score}
                          <span>/100</span>
                        </TotalScoreBox>
                      </td>
                      <td className="name">{deal.deal_name}</td>
                      <td className="lead">
                        <div className="user-wrapper">
                          <div className="pfp">
                            {user.image_url &&
                            deal.user_email === user.email ? (
                              <img src={user.image_url} />
                            ) : user.first_name &&
                              deal.user_id === user.email ? (
                              <div className="letter-wrapper">
                                {user.first_name[0].toUpperCase()}
                                {user.last_name[0].toUpperCase()}
                              </div>
                            ) : (
                              <div className="letter-wrapper">
                                {deal.user_first_name[0].toUpperCase()}
                                {deal.user_last_name[0].toUpperCase()}
                              </div>
                            )}
                            <div className="text">
                              <p>
                                {`${deal.user_first_name} ${deal.user_last_name}`}
                              </p>
                              <p className="role">{user.role && user.role}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="last-call">
                        {deal?.most_recent_call?.call_date
                          ? moment(deal.most_recent_call.call_date).format(
                              "MMM DD, YYYY"
                            )
                          : moment(deal.updated_at).format("MMM DD, YYYY")}
                      </td>
                      <td className="stage">
                        {deal.highest_scores.length > 0
                          ? deal.deal_stage
                          : "No calls added"}
                      </td>
                      <td>
                        <div className="scores-wrapper">
                          {deal.highest_scores.length > 0
                            ? deal.highest_scores.map(
                                (category: Category, index: number) => {
                                  return (
                                    <ScoreBox
                                      ref={containerRef}
                                      key={index}
                                      score={category.highest_score}
                                    >
                                      <div
                                        ref={tipRef}
                                        className="floating-advice"
                                      >
                                        <div className="triangle"></div>
                                        <p>
                                          <strong>
                                            {category.category_name}
                                          </strong>
                                        </p>
                                        <p>
                                          {category.feedback_summary &&
                                            category.feedback_summary}
                                        </p>
                                        <p>
                                          Consider a coaching session to best
                                          leverage this information as you move
                                          forward in the deal
                                        </p>
                                        <GradientButton
                                          width="90%"
                                          height="60px"
                                          fontSize={14}
                                          top={15}
                                        >
                                          <GradientText>
                                            Generate Talk Track
                                          </GradientText>
                                        </GradientButton>
                                      </div>
                                      {category.category_name[0]}
                                    </ScoreBox>
                                  );
                                }
                              )
                            : defaultScores.map(
                                (category: CategoryDefault, index: number) => (
                                  <ScoreBox
                                    key={index}
                                    score={category.highest_score}
                                  >
                                    {category.category_name[0]}
                                  </ScoreBox>
                                )
                              )}
                        </div>
                      </td>
                      <td>
                        <GreenButton
                          className={`${
                            !user?.completed_intro_tour && "deal-details"
                          }`}
                          width={"120px"}
                          height={36}
                          fontSize={12}
                          onClick={() => handleDealDetailsNavigation(deal?._id)}
                        >
                          Deal Details
                        </GreenButton>
                      </td>
                      {deal.processing ? (
                        <td className="spin-wrapper">
                          <Refresh />
                        </td>
                      ) : (
                        <td
                          className="svg-wrapper"
                          onClick={() => deleteDealChoice(deal._id)}
                        >
                          <Trash />
                        </td>
                      )}
                    </Deal>
                  </>
                );
              })
            ) : (
              <DealsLoadWrapper></DealsLoadWrapper>
            )}
          </tbody>
        </DealsTable>
      </DealsWrapper>
      <Paginate
        pageValue={10}
        pages={totalPages}
        totalRecords={totalDeals}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </PageWrapper>
  );
};

export default DealsPage;
