import React, { FC, forwardRef } from "react";
import { ToolTipWrapper } from "./ToolTip.styled";
import {
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";

interface ToolTipProps {
  boldText?: string;
  text?: string;
  buttonText?: string;
  buttonFunction?: React.MouseEventHandler<HTMLButtonElement>;
  pointSide: string;
}

const ToolTip = forwardRef<HTMLDivElement, ToolTipProps>(
  ({ boldText, text, buttonText, pointSide, buttonFunction }, ref) => {
    return (
      <ToolTipWrapper className="tool-tip-wrapper" ref={ref} pointSide={pointSide}>
        <div className="triangle"></div>
        {boldText && (
          <p>
            <strong>{boldText}</strong>
          </p>
        )}
        {text && <p>{text}</p>}
        {buttonText && buttonFunction && (
          <GradientButton onClick={buttonFunction} width="90%" height="60px" fontSize={16} top={15}>
            <GradientText>{buttonText}</GradientText>
          </GradientButton>
        )}
      </ToolTipWrapper>
    );
  }
);

export default ToolTip;
