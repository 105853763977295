import { FC, useEffect, useState, useRef } from "react";
import {
  DashboardWrapper,
  ChartsWrapper,
  ScrollChart,
  ScrollItem,
  DateCard,
  PromptCard,
  ModalWrapper,
  AdditionalQuestions,
} from "./Dashboard.styled";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading, setAddDealModal } from "state/appActions";
import store from "../../store";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import { getAverageDealScores, putUser } from "api/helpers";
import { AverageScore, DealScore } from "types";
import {
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import Sparkle from "static/svgs/Sparkle";
import {
  DropDown,
  LoginInput,
  LoginLabel,
  Select,
} from "shared/shared_styled_comps/forms.styled";
import {
  AddDealModal,
  AddDealWrapper,
} from "components/SideBar/SideBar.styled";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import Calendar from "components/Calendar/Calendar";

interface DashboardProps {}

interface TeamScore {
  team_name: string;
  users: AverageScore[];
}

interface TeamDeal {
  team_name: string;
  deals: DealScore[];
}

interface StageDeal {
  stage: string;
  deals: DealScore[];
}

const Dashboard: FC<DashboardProps> = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [averageDealScores, setAverageDealScores] = useState<AverageScore[]>();
  const [teamScores, setTeamScore] = useState<TeamScore[]>();
  const [showNoDealsModal, setShowNoDealsModal] = useState(false);
  const [dealsByStage, setDealByStage] = useState<StageDeal[]>();
  const [dealsByTeam, setDealsByTeam] = useState<TeamDeal[]>();
  const [dealScores, setDealScores] = useState<DealScore[]>();
  const [repScoreFilter, setRepScoreFilter] = useState(0);
  const [dealScoreFilter, setDealScoreFilter] = useState(0);
  const [role, setRole] = useState("");
  const [addQuestionsError, setAddQuestionsError] = useState("");
  const [linkedin_url, setLinkedinUrl] = useState("");
  const [selectedAverageScoreUser, setSelectedAverageScoreUser] =
    useState<string>("");
  const [selectedAverageScoreTeam, setSelectedAverageScoreTeam] =
    useState<string>("");
  const [selectedDealScoreTeam, setSelectedDealScoreTeam] =
    useState<string>("");
  const [selectedDealScoreStage, setSelectedDealScoreStage] =
    useState<string>("");
  let user = store.getState().auth.user;
  const isLoading = useSelector((state: any) => state.app.isLoading);

  const today = new Date();
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  const todaysDate = new Intl.DateTimeFormat("en-US", options).format(today);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node) &&
      user?.completed_intro_tour
    ) {
      setShowNoDealsModal(false);
    }
  };

  const reverse = (type: string) => {
    if (type === "rep") {
      setAverageDealScores(averageDealScores?.reverse());
      const reversedTeams = teamScores?.map((team) => ({
        ...team,
        users: [...team.users].reverse(),
      }));
      setTeamScore(reversedTeams);
    } else {
      setDealScores(dealScores?.reverse());
      const reversedTeamDeals = dealsByTeam?.map((team) => ({
        ...team,
        deals: [...team.deals].reverse(),
      }));
      setDealsByTeam(reversedTeamDeals);
      const reversedStageDeals = dealsByStage?.map((team) => ({
        ...team,
        deals: [...team.deals].reverse(),
      }));
      setDealByStage(reversedStageDeals);
    }
  };

  const sortByFullName = (a: AverageScore, b: AverageScore) => {
    const nameA =
      `${a.user_info.first_name} ${a.user_info.last_name}`.toLowerCase();
    const nameB =
      `${b.user_info.first_name} ${b.user_info.last_name}`.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const filterData = (
    avDealScores: AverageScore[],
    dealScores: DealScore[]
  ) => {
    // Step 1: Group users by team name
    const groupedUsers: { [key: string]: AverageScore[] } =
      avDealScores &&
      avDealScores?.reduce((acc: any, user: AverageScore) => {
        if (!acc[user.user_info.team_name]) {
          acc[user.user_info.team_name] = [];
        }
        acc[user.user_info.team_name].push(user);
        return acc;
      }, {} as { [key: string]: AverageScore[] });

    // Step 2: Convert the grouped object into an array for easier display
    const teams = Object.keys(groupedUsers).map((team_name) => ({
      team_name,
      users: groupedUsers[team_name],
    }));

    // Group deals by team name
    const teamGroupedDeals: { [key: string]: DealScore[] } = dealScores.reduce(
      (acc: any, deal: DealScore) => {
        if (!acc[deal.team_name]) {
          acc[deal.team_name] = [];
        }
        acc[deal.team_name].push(deal);
        return acc;
      },
      {} as { [key: string]: DealScore[] }
    );

    // Step 2: Convert the grouped object into an array for easier display
    const teamDeals = Object.keys(teamGroupedDeals).map((team_name) => ({
      team_name,
      deals: teamGroupedDeals[team_name],
    }));

    // Group deals by team name
    const stageGroupedDeals: { [key: string]: DealScore[] } = dealScores.reduce(
      (acc: any, deal: DealScore) => {
        if (!acc[deal.deal_stage]) {
          acc[deal.deal_stage] = [];
        }
        acc[deal.deal_stage].push(deal);
        return acc;
      },
      {} as { [key: string]: DealScore[] }
    );

    // Step 2: Convert the grouped object into an array for easier display
    const stageDeals = Object.keys(stageGroupedDeals).map((stage) => ({
      stage,
      deals: stageGroupedDeals[stage],
    }));

    setTeamScore(teams);
    setDealByStage(stageDeals);
    setDealsByTeam(teamDeals);
  };

  const handleContinue = async () => {
    if (!linkedin_url || !role) {
      setAddQuestionsError("Please answer all questions.");
    } else {
      dispatch(startLoading());
      let response = await putUser({
        linkedin_url,
        role,
      });
      window.location.reload();
    }
  };

  useEffect(() => {
    const promiseAll = async () => {
      dispatch(startLoading());
      const [averageDealScoresRequest] = await Promise.all([
        getAverageDealScores(),
      ]);
      console.log("Average Deal Scores: ", averageDealScoresRequest);
      let avDealScores = averageDealScoresRequest?.average_deal_scores_per_user;
      const dealScores: DealScore[] = [];
      for (const user of avDealScores) {
        dealScores.push(...user.deals);
      }
      if (!dealScores?.length) {
        // Handle the case where there are no deals yet
        setShowNoDealsModal(true);
      } else {
        // Sort
        avDealScores?.sort(
          (a: AverageScore, b: AverageScore) =>
            b.average_score - a.average_score
        );
        dealScores?.sort(
          (a: DealScore, b: DealScore) => b.deal_score - a.deal_score
        );
        setAverageDealScores(avDealScores);
        setDealScores(dealScores);
        if (avDealScores && dealScores) {
          filterData(avDealScores, dealScores);
        }
      }
      dispatch(endLoading());
    };
    promiseAll();
  }, []);

  const getUniqueTeamNames = (scores: AverageScore[] | undefined) => {
    if (!scores) {
      return [];
    }
    return Array.from(
      new Set(scores.map((score) => score.user_info.team_name))
    ).sort();
  };

  const getUniqueTeamNamesDeal = (scores: DealScore[] | undefined) => {
    if (!scores) {
      return [];
    }
    return Array.from(new Set(scores.map((score) => score.team_name))).sort();
  };

  const getUniqueStages = (scores: DealScore[] | undefined) => {
    if (!scores) {
      return [];
    }
    return Array.from(new Set(scores.map((score) => score.deal_stage))).sort();
  };

  return (
    <PageWrapper pageTitle="Dashboard">
      <Calendar />
      <ChartsWrapper>
        <ScrollChart>
          <h2>Top Reps by Score</h2>
          <div className="filter-wrapper">
            <p>Filter by:</p>
            <p
              className="tab"
              onClick={() => setRepScoreFilter(0)}
              style={{ backgroundColor: repScoreFilter === 0 ? "#35373E" : "" }}
            >
              Individual
            </p>
            <p
              className="tab"
              onClick={() => setRepScoreFilter(1)}
              style={{ backgroundColor: repScoreFilter === 1 ? "#35373E" : "" }}
            >
              Team
            </p>
          </div>
          <div className="sort-flex">
            <div className="sort" onClick={() => reverse("rep")}>
              <p>Sort ⇅</p>
            </div>
            {repScoreFilter === 0 ? (
              <DropDown
                onChange={(e) => setSelectedAverageScoreUser(e.target.value)}
                value={selectedAverageScoreUser}
              >
                <option value="">All Reps</option>
                {averageDealScores?.sort(sortByFullName).map((score, index) => (
                  <option
                    key={index}
                    value={`${score.user_info.first_name} ${score.user_info.last_name}`}
                  >
                    {`${score.user_info.first_name} ${score.user_info.last_name}`}
                  </option>
                ))}
              </DropDown>
            ) : (
              <DropDown
                onChange={(e) => setSelectedAverageScoreTeam(e.target.value)}
                value={selectedAverageScoreTeam}
              >
                <option value="">All Teams</option>
                {getUniqueTeamNames(averageDealScores).map((team, index) => (
                  <option key={index} value={team}>
                    {team}
                  </option>
                ))}
              </DropDown>
            )}
          </div>
          <div className="scroll-wrapper">
            {repScoreFilter === 0
              ? averageDealScores
                  ?.filter(
                    (score) =>
                      selectedAverageScoreUser === "" ||
                      `${score.user_info.first_name} ${score.user_info.last_name}` ===
                        selectedAverageScoreUser
                  )
                  .map((averageScore: AverageScore, index: number) => {
                    return (
                      <ScrollItem
                        borderValue={averageScore.average_score}
                        colorValue={averageScore.average_score / 10}
                        key={index}
                      >
                        <div>
                          <div className="circular-border">
                            <div className="image-circle-border">
                              <div className="image-circle">
                                {averageScore.user_info.image_url ? (
                                  <img src={averageScore.user_info.image_url} />
                                ) : (
                                  user.first_name && (
                                    <div className="letter-wrapper">
                                      {averageScore?.user_info.first_name
                                        ?.length > 0
                                        ? averageScore?.user_info.first_name[0]?.toUpperCase()
                                        : averageScore?.user_info.first_name}
                                      {averageScore?.user_info.last_name
                                        ?.length > 0
                                        ? averageScore?.user_info.last_name[0]?.toUpperCase()
                                        : averageScore?.user_info.last_name}
                                      {/* {averageScore?.user_first_name[0]?.toUpperCase()}
                                  {averageScore?.user_last_name[0]?.toUpperCase()} */}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <p className="score">
                            <span>{averageScore.average_score.toFixed(1)}</span>{" "}
                            / 100
                          </p>
                        </div>
                        <div className="text-wrapper">
                          <h4>
                            {averageScore.user_info.first_name}{" "}
                            {averageScore.user_info.last_name}
                          </h4>
                          <p>Deals: {averageScore.user_info.number_of_deals}</p>
                        </div>
                      </ScrollItem>
                    );
                  })
              : teamScores
                  ?.filter(
                    (score) =>
                      selectedAverageScoreTeam === "" ||
                      score.team_name === selectedAverageScoreTeam
                  )
                  .map((teamScore: TeamScore, index: number) => {
                    return (
                      <>
                        <h4>{teamScore.team_name}</h4>
                        {teamScore.users.map(
                          (averageScore: AverageScore, index: number) => (
                            <ScrollItem
                              borderValue={averageScore.average_score}
                              colorValue={averageScore.average_score / 10}
                              key={index}
                            >
                              <div>
                                <div className="circular-border">
                                  <div className="image-circle-border">
                                    <div className="image-circle">
                                      {averageScore.user_info.image_url ? (
                                        <img
                                          src={averageScore.user_info.image_url}
                                        />
                                      ) : (
                                        user.first_name && (
                                          <div className="letter-wrapper">
                                            {averageScore &&
                                            averageScore.user_info.first_name &&
                                            averageScore.user_info.first_name
                                              .length > 0
                                              ? averageScore?.user_info.first_name[0]?.toUpperCase()
                                              : averageScore?.user_info
                                                  .first_name}
                                            {averageScore &&
                                            averageScore.user_info.last_name &&
                                            averageScore.user_info.last_name
                                              .length > 0
                                              ? averageScore?.user_info.last_name[0]?.toUpperCase()
                                              : averageScore?.user_info
                                                  .last_name}
                                            {/* {averageScore?.user_first_name[0]?.toUpperCase()}
                                    {averageScore?.user_last_name[0]?.toUpperCase()} */}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <p className="score">
                                  <span>
                                    {averageScore.average_score.toFixed(1)}
                                  </span>{" "}
                                  / 100
                                </p>
                              </div>
                              <div className="text-wrapper">
                                <h4>
                                  {averageScore.user_info.first_name}{" "}
                                  {averageScore.user_info.last_name}
                                </h4>
                                <p>
                                  Deals:{" "}
                                  {averageScore.user_info.number_of_deals}
                                </p>
                              </div>
                            </ScrollItem>
                          )
                        )}
                      </>
                    );
                  })}
          </div>
        </ScrollChart>
        <ScrollChart>
          <h2>Top Deals by Score</h2>
          <div className="filter-wrapper">
            <p>Filter deals by:</p>
            <p
              className="tab"
              onClick={() => setDealScoreFilter(0)}
              style={{
                backgroundColor: dealScoreFilter === 0 ? "#35373E" : "",
              }}
            >
              Overall
            </p>
            <p
              className="tab"
              onClick={() => setDealScoreFilter(1)}
              style={{
                backgroundColor: dealScoreFilter === 1 ? "#35373E" : "",
              }}
            >
              Team
            </p>
            <p
              className="tab"
              onClick={() => setDealScoreFilter(2)}
              style={{
                backgroundColor: dealScoreFilter === 2 ? "#35373E" : "",
              }}
            >
              Stage
            </p>
          </div>
          <div className="sort-flex">
            <div className="sort" onClick={() => reverse("deal")}>
              <p>Sort ⇅</p>
            </div>
            {dealScoreFilter === 1 && (
              <DropDown
                onChange={(e) => setSelectedDealScoreTeam(e.target.value)}
                value={selectedDealScoreTeam}
              >
                <option value="">All Teams</option>
                {getUniqueTeamNamesDeal(dealScores).map((team, index) => (
                  <option key={index} value={team}>
                    {team}
                  </option>
                ))}
              </DropDown>
            )}
            {dealScoreFilter === 2 && (
              <DropDown
                onChange={(e) => setSelectedDealScoreStage(e.target.value)}
                value={selectedDealScoreStage}
              >
                <option value="">All Stages</option>
                {getUniqueStages(dealScores).map((stage, index) => (
                  <option key={index} value={stage}>
                    {stage}
                  </option>
                ))}
              </DropDown>
            )}
          </div>
          <div className="scroll-wrapper">
            {dealScoreFilter === 0
              ? dealScores?.map((dealScore: DealScore, index: number) => {
                  return (
                    <ScrollItem
                      colorValue={dealScore.deal_score / 10}
                      borderValue={dealScore.deal_score}
                      key={index}
                    >
                      <div>
                        <div className="circular-border">
                          <div className="image-circle-border">
                            <div className="image-circle">
                              <div className="letter-wrapper">
                                {dealScore &&
                                dealScore.deal_name &&
                                dealScore.deal_name.length > 0
                                  ? dealScore?.deal_name[0]?.toUpperCase()
                                  : dealScore.deal_name}
                                {/* {dealScore?.name[0]?.toUpperCase()} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="score">
                          <span>
                            {dealScore.deal_score
                              ? dealScore.deal_score.toFixed(1)
                              : 0}
                          </span>{" "}
                          / 100
                        </p>
                      </div>
                      <div className="text-wrapper">
                        <h4>{dealScore.deal_name}</h4>
                        <p>Stage: {dealScore.deal_stage}</p>
                      </div>
                    </ScrollItem>
                  );
                })
              : dealScoreFilter === 1
              ? dealsByTeam
                  ?.filter(
                    (deal) =>
                      selectedDealScoreTeam === "" ||
                      deal.team_name === selectedDealScoreTeam
                  )
                  .map((teamDealScore: TeamDeal, index: number) => {
                    return (
                      <>
                        <h4>{teamDealScore.team_name}</h4>
                        {teamDealScore.deals.map(
                          (dealScore: DealScore, index: number) => (
                            <ScrollItem
                              colorValue={dealScore.deal_score / 10}
                              borderValue={dealScore.deal_score}
                              key={index}
                            >
                              <div>
                                <div className="circular-border">
                                  <div className="image-circle-border">
                                    <div className="image-circle">
                                      <div className="letter-wrapper">
                                        {dealScore &&
                                        dealScore.deal_name &&
                                        dealScore.deal_name.length > 0
                                          ? dealScore?.deal_name[0]?.toUpperCase()
                                          : dealScore.deal_name}
                                        {/* {dealScore?.name[0]?.toUpperCase()} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p className="score">
                                  <span>
                                    {dealScore.deal_score
                                      ? dealScore.deal_score.toFixed(1)
                                      : 0}
                                  </span>{" "}
                                  / 100
                                </p>
                              </div>
                              <div className="text-wrapper">
                                <h4>{dealScore.deal_name}</h4>
                                <p>Stage: {dealScore.deal_stage}</p>
                              </div>
                            </ScrollItem>
                          )
                        )}
                      </>
                    );
                  })
              : dealsByStage
                  ?.filter(
                    (deal) =>
                      selectedDealScoreStage === "" ||
                      deal.stage === selectedDealScoreStage
                  )
                  .map((stageDealScore: StageDeal, index: number) => {
                    return (
                      <>
                        <h4>{stageDealScore.stage}</h4>
                        {stageDealScore.deals.map(
                          (dealScore: DealScore, index: number) => (
                            <ScrollItem
                              colorValue={dealScore.deal_score / 10}
                              borderValue={dealScore.deal_score}
                              key={index}
                            >
                              <div>
                                <div className="circular-border">
                                  <div className="image-circle-border">
                                    <div className="image-circle">
                                      <div className="letter-wrapper">
                                        {dealScore &&
                                        dealScore.deal_name &&
                                        dealScore.deal_name.length > 0
                                          ? dealScore?.deal_name[0]?.toUpperCase()
                                          : dealScore.deal_name}
                                        {/* {dealScore?.name[0]?.toUpperCase()} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p className="score">
                                  <span>
                                    {dealScore.deal_score
                                      ? dealScore.deal_score.toFixed(1)
                                      : 0}
                                  </span>{" "}
                                  / 100
                                </p>
                              </div>
                              <div className="text-wrapper">
                                <h4>{dealScore.deal_name}</h4>
                                <p>Stage: {dealScore.deal_stage}</p>
                              </div>
                            </ScrollItem>
                          )
                        )}
                      </>
                    );
                  })}
          </div>
        </ScrollChart>
        {/* <ScrollChart className="schedule">
          <h2>Schedule</h2>
          <div className="filter-wrapper">
            <p>{todaysDate}</p>
          </div>
          <div className="scroll-wrapper">
            <DateCard>
              <GradientButton
                width={"40%"}
                height={"30px"}
                fontSize={14}
                top={170}
              >
                <Sparkle />
                <GradientText>Run Pre-Call Prep</GradientText>
              </GradientButton>
              <p className="date">08:00-08:30 am, PT</p>
              <h4>Michael Smith</h4>
              <h5>Chief Technology Officer - Dario Health</h5>
              <div className="next-wrapper">
                <h3>Next Steps</h3>
                <p>First call: Pre-Call Prep</p>
                <div className="red-circle">X</div>
              </div>
              <p>
                This is your first call, run a prep-call prep to recieve
                insights before your next call.
              </p>
            </DateCard>
            <DateCard>
              <GradientButton
                width={"40%"}
                height={"30px"}
                fontSize={14}
                top={265}
              >
                <Sparkle />
                <GradientText>Get Coaching</GradientText>
              </GradientButton>
              <p className="date">10:00-11:00 am, PT</p>
              <h4>Jane Cooper</h4>
              <h5>Lead Product Manager - Talend</h5>
              <div className="next-wrapper">
                <h3>Next Steps</h3>
                <p>
                  First call: <a>Pre-Call Prep</a>
                </p>
                <div className="green-circle">&#x2713;</div>
              </div>
              <ul>
                <li>
                  Focus on understanding their security challenges and how they
                  currently handle threat detection.
                </li>
                <li>
                  Ask questions to uncover their pain points and success
                  metrics, which will help tailor your pitch to their needs.
                </li>
              </ul>
            </DateCard>
          </div>
        </ScrollChart> */}
        <ScrollChart className="schedule">
          <h2>Popular Coach Prompts</h2>
          <div className="scroll-wrapper-2">
            <PromptCard>
              <GradientButton
                width={"70%"}
                height={"40px"}
                fontSize={14}
                top={10}
                style={{ left: "160px" }}
                onClick={() => navigate("/app/dealCoach")}
              >
                <Sparkle />
                <GradientText>Use Deal Coach to personalize these</GradientText>
              </GradientButton>
              <p style={{ marginBottom: "5px" }} className="date">
                | Used X last week
              </p>
              <h5 style={{ marginBottom: "20px" }}>Full Sales Team</h5>
              <p>
                Guide me on how to{" "}
                <strong>tailor the 'Solution Fit Analysis Document'</strong>
                to match the client's specific criteria closely.
              </p>
            </PromptCard>
            <PromptCard>
              <GradientButton
                width={"70%"}
                height={"40px"}
                fontSize={14}
                top={10}
                style={{ left: "160px" }}
                onClick={() => navigate("/app/dealCoach")}
              >
                <Sparkle />
                <GradientText>Use Deal Coach to personalize these</GradientText>
              </GradientButton>
              <p style={{ marginBottom: "5px" }} className="date">
                | Used X last week
              </p>
              <h5 style={{ marginBottom: "20px" }}>Full Sales Team</h5>
              <p>
                How should I{" "}
                <strong>prepare for discussing ROI and contract terms</strong>{" "}
                with the client's CTO in the final review meeting?
              </p>
            </PromptCard>
          </div>
        </ScrollChart>
        <ScrollChart className="schedule">
          <h2>Top Coaching Recommendations</h2>
          <div className="scroll-wrapper-2">
            <div className="container">
              <div className="category-box">M</div>
              <p>
                Focus on metrics that demonstrate ROI, such as cost savings,
                revenue increase, and efficiency improvements.
              </p>
            </div>
            <div className="container">
              <div className="category-box">E</div>
              <p>
                Identify the economic buyer early in the sales process and
                tailor your approach to their priorities.
              </p>
            </div>
            <div className="container">
              <div className="category-box">Dc</div>
              <p>
                Fully understand the decision criteria and aim to influence them
                in favor of your solution
              </p>
            </div>
            <div className="container">
              <div className="category-box">Dp</div>
              <p>
                Clearly map out the client's decision-making process, including
                all key stakeholders and timelines.
              </p>
            </div>
            <div className="container">
              <div className="category-box">P</div>
              <p>
                Ensure all necessary documentation is prepared and easy to
                access.
              </p>
            </div>
            <div className="container">
              <div className="category-box">I</div>
              <p>Conduct a thorough analysis of the client’s pain points.</p>
            </div>
            <div className="container">
              <div className="category-box">Ch</div>
              <p>
                Identify and nurture relationships with internal champions who
                can advocate for your solution.
              </p>
            </div>
            <div className="container">
              <div className="category-box">Co</div>
              <p>
                Identify what other competition exists and how you can
                materialize a real life comparison to value your team.
              </p>
            </div>
          </div>
        </ScrollChart>
      </ChartsWrapper>
      {showNoDealsModal && user.completed_intro_tour && (
        <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
          <AddDealModal ref={modalRef}>
            <ModalWrapper>
              <div>
                <LeadoffRobot />
              </div>
              <h3>No deals exist to show in the dashboard.</h3>
              <h4>Please add a new deal to enable dashboard functionality.</h4>
              <GradientButton
                onClick={() => {
                  setShowNoDealsModal(false);
                  dispatch(setAddDealModal(true));
                }}
                width="80%"
                height="60px"
                fontSize={14}
                top={130}
              >
                Add A New&nbsp;<GradientText>Deal</GradientText>
              </GradientButton>
            </ModalWrapper>
          </AddDealModal>
        </AddDealWrapper>
      )}
      {user.email && !user.linkedin_url && (
        <AdditionalQuestions>
          <div>
            <div className="modal-wrapper">
              <h3>Two more questions to begin.</h3>
              {addQuestionsError && (
                <p className="error">{addQuestionsError}</p>
              )}
              <LoginLabel>
                What's your linkedin URL?
                <div className="">
                  <LoginInput
                    type="text"
                    name="linkedin_url"
                    value={linkedin_url}
                    placeholder="https://www.linkedin.com/in/<your_username>/"
                    onChange={(e) => setLinkedinUrl(e.target.value)}
                  />
                  {/* <p className="subtext">
                    Please share the URL to your LinkedIn profile so that
                    Leadoff can support your growth as a rep, regardless of
                    where you work. Our goal is to help you build a portfolio of
                    sales successes and effective behaviors. This URL will serve
                    as a unique identifier, allowing us to recognize you across
                    accounts and provide tailored coaching and assistance
                    throughout your career. Rest assured, while we track your
                    past activity, we will never share deal data across
                    different company accounts.
                  </p> */}
                </div>
              </LoginLabel>
              <LoginLabel>
                What's your role?
                <Select
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value={""}>Select a role</option>
                  <option value={"BDR/SDR"}>BDR/SDR</option>
                  <option value={"SMB Account Executive"}>
                    SMB Account Executive
                  </option>
                  <option value={"Mid-Market Account Executive"}>
                    Mid-Market Account Executive
                  </option>
                  <option value={"Enterprise Account Executive"}>
                    Enterprise Account Executive
                  </option>
                  <option value={"Global and Strategic Account Executive"}>
                    Global and Strategic Account Executive
                  </option>
                  <option value={"Sales Manager"}>Sales Manager</option>
                  <option value={"Sales Leader"}>Sales Leader</option>
                  <option value={"Customer Success Manager"}>
                    Customer Success Manager
                  </option>
                  <option value={"Sales/Revenue Enablement"}>
                    Sales/Revenue Enablement
                  </option>
                  <option value={"Sales Engineer/Solutions Consultant"}>
                    Sales Engineer/Solutions Consultant
                  </option>
                  <option value={"Other"}>Other</option>
                </Select>
              </LoginLabel>
              <GradientButton
                onClick={() => handleContinue()}
                width="80%"
                height="60px"
                fontSize={14}
                top={40}
              >
                <GradientText>Continue</GradientText>
              </GradientButton>
            </div>
          </div>
        </AdditionalQuestions>
      )}
    </PageWrapper>
  );
};

export default Dashboard;
