import React, { FC, useRef, useState } from "react";
import { TranscriptWrapper } from "./Transcript.styled";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import { GreenButton } from "shared/shared_styled_comps/components.styled";

interface TranscriptProps {
  transcript?: string;
}

const Transcript: FC<TranscriptProps> = ({ transcript }) => {
  const textContainerRef = useRef<HTMLDivElement | null>(null);
  const [highlightedText, setHighlightedText] = useState<string>("");

  const highlightText = (searchString: string) => {
    if (!textContainerRef.current) return;

    const container = textContainerRef.current;

    // Function to recursively find text nodes and highlight them
    const recursiveHighlight = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeText = node.textContent || "";

        // Check if the node text contains the search string (partial match allowed)
        const searchIndex = nodeText
          .toLowerCase()
          .indexOf(searchString.toLowerCase());

        if (searchIndex !== -1) {
          // Split the text node into three parts: before, match, after
          const beforeMatch = nodeText.substring(0, searchIndex);
          const match = nodeText.substring(
            searchIndex,
            searchIndex + searchString.length
          );
          const afterMatch = nodeText.substring(
            searchIndex + searchString.length
          );

          // Replace the original text node with three new nodes: text-before, highlighted span, text-after
          const highlightedSpan = document.createElement("span");
          highlightedSpan.className = "highlight";
          highlightedSpan.textContent = match;

          const fragment = document.createDocumentFragment();
          if (beforeMatch)
            fragment.appendChild(document.createTextNode(beforeMatch));
          fragment.appendChild(highlightedSpan);
          if (afterMatch)
            fragment.appendChild(document.createTextNode(afterMatch));

          node.parentNode?.replaceChild(fragment, node);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && node.hasChildNodes()) {
        node.childNodes.forEach((child) => recursiveHighlight(child));
      }
    };

    // Remove any previous highlights
    const removePreviousHighlights = () => {
      const highlightedElements = container.querySelectorAll(".highlight");
      highlightedElements.forEach((el) => {
        const parent = el.parentNode;
        if (parent) {
          parent.replaceChild(
            document.createTextNode(el.textContent || ""),
            el
          );
          parent.normalize(); // Merge text nodes
        }
      });
    };

    // Remove previous highlights before applying a new one
    removePreviousHighlights();

    // Apply highlights for the search string
    recursiveHighlight(container);

    // Scroll to the first occurrence of the highlighted element
    const firstHighlight = container.querySelector(".highlight");
    if (firstHighlight) {
      firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <TranscriptWrapper>
      <div className="search-wrapper">
        <SearchBar
          value={highlightedText}
          updateFunction={setHighlightedText}
          submitFunction={() => highlightText(highlightedText)}
        />
        <GreenButton onClick={() => highlightText(highlightedText)} width="125px" height={"30"} fontSize={12}>
          Search Transcript
        </GreenButton>
      </div>
      {transcript && (
        <div
          ref={textContainerRef}
          dangerouslySetInnerHTML={{ __html: transcript }}
          className="transcript-content"
        />
      )}
    </TranscriptWrapper>
  );
};

export default Transcript;
