import React from "react"
import { FooterWrapper } from "./Footer.styled"
import LogoWithText from "static/svgs/LogoWithText"
import Twitter from "static/svgs/Twitter"
import Youtube from "static/svgs/Youtube"
import Instagram from "static/svgs/Instagram"
import { useLocation, useNavigate } from "react-router-dom"
type Props = {}

const Footer = (props: Props) => {
  const navigate = useNavigate();
  return (
    <FooterWrapper>
      <div className="footer-left">
        <div>
          <LogoWithText />
          <p>Empower, Engage and Level Up</p>
          <Youtube />
          <Instagram />
          <Twitter />
        </div>
      </div>
      <div className="footer-right">
        <div>
          <div>
            {/* <ul>
              <li className="list-header">Product</li>
              <li>Features</li>
              <li>Integrations</li>
              <li>Pricing</li>
              <li>Changelog</li>
              <li>Docs</li>
            </ul> */}
          </div>
          <div>
            <ul>
              <li className="list-header">Company</li>
              <li onClick={() => navigate("/team")}>About Us</li>
              <li onClick={() => navigate("/blog")}>Blog</li>
              {/* <li>Careers</li> */}
            </ul>
          </div>
          <div>
            <ul>
              <li className="list-header">Resources</li>
              <li>Community</li>
              <li>Contact</li>
              <li onClick={() => navigate("/privacy")}>Privacy Policy</li>
              <li onClick={() => navigate("/terms")}>Terms of Service</li>
            </ul>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer
