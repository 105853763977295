import React, { FC, useEffect, useState } from "react";
import { startLoading, endLoading } from "state/appActions";
import { ImpersonateWrapper } from "./Impersonate.styled";
import { getUser, impersonateUser, login as loginPost } from "api/helpers";
import axios from "axios";
import { Dispatch } from "redux";
import {
  LoginInput,
  LoginLabel,
  SquareButton,
} from "shared/shared_styled_comps/forms.styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetUser } from "types";
import { login, setUserData, logout } from "auth/authActions";
import { MarketingPageWrapper } from "shared/shared_styled_comps/components.styled";

interface ImpersonateProps {}

const Impersonate: FC<ImpersonateProps> = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);

  const loginAsync = async (dispatch: Dispatch) => {
    const data = {
      email,
    };
    // Make the login request
    const response = await impersonateUser(data);
    if (axios.isAxiosError(response)) {
      // Handle AxiosError
      setError(response?.response?.data?.message);
      return { success: false, message: response?.response?.data?.message };
    } else {
      if (response?.data?.message === "success") {
        dispatch(login());
        let data: GetUser = await getUser();
        dispatch(setUserData(data.user));
        return { success: true };
      }
      return { success: false };
    }
  };

  useEffect(() => {
    if (user.user_role !== "super_admin") {
      dispatch(logout());
      navigate("/login");
    }
  }, []);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    let response = await loginAsync(dispatch);
    if (!response.success) {
      if (response.message) {
        setError(response.message);
      } else {
        setError("Login failed for an unknown reason.");
      }
    }
    dispatch(endLoading());
    if (response.success) {
      navigate("/app/deals");
    }
  };

  return (
    <MarketingPageWrapper>
      <ImpersonateWrapper>
        <form style={{ width: "100%" }} onSubmit={(e) => handleLogin(e)}>
          <LoginLabel>
            User's Email:
            <LoginInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </LoginLabel>
          <SquareButton type="submit">Impersonate</SquareButton>
          {error && <p className="error">{error}</p>}
        </form>
      </ImpersonateWrapper>
    </MarketingPageWrapper>
  );
};

export default Impersonate;
