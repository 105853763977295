import axios from "axios";
import globalRouter from "globalRouter";
import { Store } from "redux";
import { endLoading, setError } from "state/appActions";
import type { AxiosRequestConfig } from 'axios';

export let axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`
});

export const setupAxiosInstance = (store: Store) => {
  axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
  });

  axios.defaults.withCredentials = true;

  axiosInstance.interceptors.request.use(
    (config) => {
      // Do not overwrite form content type
      if (config.headers["Content-Type"] !== "multipart/form-data") {
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {

      const originalRequest = error.config;
      if (error.response && error.response.status === 401) {
        debugger;
        if (
          error?.response?.data?.message?.includes("Token Expired")
        ) {
          store.dispatch(endLoading());
          if (globalRouter.navigate)
            globalRouter.navigate("/login?sessiontimeout=true");
          return;
        } else {
          return error;
        }
      } else if (error.response && error.response.status === 503) {
        store.dispatch(endLoading());
        if (globalRouter.navigate)
          globalRouter.navigate("/maintenance");
      } else if (error?.response?.data?.status === "error") {
        store.dispatch(endLoading());
        //store.dispatch(setError(error?.response?.data?.message));
      }

      // Option to dispatch items here too
      //   store.dispatch({
      //     type: 'API_ERROR',
      //     payload: error,
      // })
      // In you reducers...
      // reduce(state, action) {
      //   if (action.type === 'API_ERROR' && action.payload.statusCode === 401) {
      //       return { // whatever needed }
      //   }
      //   return state;
      //}

      // Refresh token logic for later
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true
      //   const refreshToken = localStorageService.getRefreshToken()
      //   return axios
      //     .post('/auth/token', {
      //       refresh_token: refreshToken
      //     })
      //     .then(res => {
      //       if (res.status === 201) {
      //         localStorageService.setToken(res.data)
      //         axios.defaults.headers.common['Authorization'] =
      //           'Bearer ' + localStorageService.getAccessToken()
      //         return axios(originalRequest)
      //       }
      //     })
      // }
    }
  );
};
